import React, { useState, useEffect } from 'react';
import { AppBar, Tabs, Tab, Box } from '@mui/material';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import SummaryScreen from './SummaryScreen';
import TemplateEditScreen from './TemplateEditScreen';
// import UserManagementScreen from './UserManagementScreen'; // 今はコメントアウトしています
import Login from './Login';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('isAdmin') === 'true');
  const navigate = useNavigate();
  const location = useLocation();

  const tabPaths = ['/summary', '/edit']; // '/user-management' を削除しました
  const currentTabIndex = Math.max(tabPaths.indexOf(location.pathname), 0);

  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLoggedIn);
    localStorage.setItem('isAdmin', isAdmin);
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, isAdmin, navigate]);

  useEffect(() => {
    const currentPathIndex = tabPaths.indexOf(location.pathname);
    if (currentPathIndex === -1 && isLoggedIn) {
      navigate('/summary');
    }
  }, [location, navigate, isLoggedIn]);

  const handleTabChange = (event, newValue) => {
    navigate(tabPaths[newValue]);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Tabs
          aria-label="simple tabs example"
          value={currentTabIndex}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="要約画面" />
          <Tab label="テンプレート編集画面" />
          {/* isAdmin && <Tab label="ユーザー管理" /> */}
        </Tabs>
      </AppBar>
      <Routes>
        <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} setIsAdmin={setIsAdmin} />} />
        <Route path="/summary" element={isLoggedIn ? <SummaryScreen /> : <Navigate to="/login" />} />
        <Route path="/edit" element={isLoggedIn && isAdmin ? <TemplateEditScreen /> : <Navigate to="/login" />} />
        {/* <Route path="/user-management" element={isLoggedIn && isAdmin ? <UserManagementScreen /> : <Navigate to="/login" />} /> */}
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Box>
  );
}

export default App;

