import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function TemplateEditScreen() {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch('https://collabos-summary.voitex.site/api/templates', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setTemplates(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Fetch error:', error);
        setIsLoading(false);
      });
  }, []);

  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template);
    setTitle(template.title);
    setContent(template.content);
  };

  const handleDeleteTemplate = (id) => {
    // API呼び出しでテンプレートを削除
    setIsLoading(true);
    fetch(`https://collabos-summary.voitex.site/api/templates/${id}`, {
      method: 'DELETE',
    })
    .then(response => {
      if (response.ok) {
        setTemplates(templates.filter(template => template.id !== id));
      } else {
        throw new Error('Template could not be deleted');
      }
    })
    .catch(error => console.error('Delete error:', error))
    .finally(() => setIsLoading(false));
  };

  const handleSave = () => {
    const method = selectedTemplate ? 'PUT' : 'POST';
    const apiURL = selectedTemplate
      ? `https://collabos-summary.voitex.site/api/templates/${selectedTemplate.id}`
      : 'https://collabos-summary.voitex.site/api/templates';

    // ユーザーIDを取得（仮に1としますが、実際にはログイン時に取得したIDを使用する）
    const userId = 1; // ここを適切に書き換える必要があります

    // API呼び出しでテンプレートを保存または更新
    setIsLoading(true);
    fetch(apiURL, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ title, content, user_id: userId }), // user_idを追加
    })

    .then(response => response.json())
    .then(data => {
      if (selectedTemplate) {
        setTemplates(templates.map(t => (t.id === selectedTemplate.id ? data : t)));
      } else {
        setTemplates([...templates, data]);
      }
      setTitle('');
      setContent('');
      setSelectedTemplate(null);
    })
    .catch(error => console.error('Save error:', error))
    .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ m: 2 }}>
      <TextField
        label="タイトル"
        fullWidth
        margin="normal"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        label="内容"
        fullWidth
        multiline
        rows={20}
        margin="normal"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <Button variant="contained" onClick={handleSave} sx={{ mb: 2 }}>
        保存
      </Button>
      <List>
        {templates.map((template) => (
          <ListItem key={template.id} button onClick={() => handleSelectTemplate(template)}>
            <ListItemText primary={template.title} secondary={template.content} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTemplate(template.id)}>
                <DeleteIcon />
              </IconButton>
              <IconButton edge="end" aria-label="edit">
                <EditIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default TemplateEditScreen;
