import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, MenuItem, Typography, CircularProgress } from '@mui/material';

function SummaryScreen() {
  const [text, setText] = useState('');
  const [template, setTemplate] = useState('');
  const [templates, setTemplates] = useState([]);
  const [summary, setSummary] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
      const fetchTemplates = async () => {
          try {
              const response = await fetch('https://collabos-summary.voitex.site/api/templates');
              if (!response.ok) throw new Error('Failed to fetch');
              const data = await response.json();
              setTemplates(data);
          } catch (error) {
              console.error('Error:', error);
          }
      };
      fetchTemplates();
  }, []);

  const fetchSummary = () => {
    const selectedTemplate = templates.find(t => t.id === template); // 選択されたテンプレートの詳細を取得
    const fullPrompt = selectedTemplate ? `${selectedTemplate.content} ${text}` : text; // テンプレートの内容とユーザー入力を結合

    setIsLoading(true);
    fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
      },
      body: JSON.stringify({
        model: "gpt-4",
        messages: [{ role: "user", content: fullPrompt }]
      })
    })
    .then(response => response.json())
    .then(data => {
      setSummary(data.choices[0].message.content.trim());
      setIsLoading(false);
    })
    .catch(error => {
      console.error('Error:', error);
      setSummary("要約の取得に失敗しました。");
      setIsLoading(false);
    });
  };

  const clearFields = () => {
    setText('');
    setSummary('');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(summary).then(() => {
      alert('要約をクリップボードにコピーしました。');
    });
  };

  return (
    <Box sx={{ m: 2 }}>
      <TextField
        fullWidth
        label="テキストを入力"
        multiline
        rows={10}
        value={text}
        onChange={(e) => setText(e.target.value)}
        variant="outlined"
        margin="normal"
      />

      <TextField
        select
        fullWidth
        label="プロンプトテンプレートを選択"
        value={template}
        onChange={(e) => setTemplate(e.target.value)}
        margin="normal"
      >
        {templates.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.title}
          </MenuItem>
        ))}
      </TextField>

      <Button variant="contained" onClick={fetchSummary} disabled={isLoading} sx={{ mb: 2 }}>
        {isLoading ? '要約中...' : '要約する'}
      </Button>
      
      <Button variant="outlined" onClick={clearFields} sx={{ mx: 1 }}>
        クリア
      </Button>

      <Button variant="outlined" onClick={copyToClipboard} sx={{ mx: 1 }}>
        コピー
      </Button>

      {isLoading && <CircularProgress size={24} sx={{ ml: 2 }} />}

      <Typography variant="h6" gutterBottom component="div">
        要約結果
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={10}
        value={summary}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
    </Box>
  );
}

export default SummaryScreen;
